








import { Vue, Component } from 'vue-property-decorator';
import Loading from 'vue-loading-overlay';

import Loader from '@/shared/resources/components/Loader.vue';

@Component({
  components: {
    Loader,
    Loading,
  },
})
export default class LoaderOverlay extends Vue {
}
