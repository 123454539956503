



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vuelidate';

import FormButtons from '@/shared/resources/components/forms/FormButtons.vue';
import Loader from '@/shared/resources/components/Loader.vue';
import LoaderOverlay from '@/shared/resources/components/LoaderOverlay.vue';

import ORMModel from '@/core/bridge/orm/ORMModel';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import FormSaveActionsEnum from '@/shared/lib/support/forms/enums/FormSaveActionsEnum';
import Popup from '@/shared/lib/support/popups/Popup';
import FormActionTypesEnum from '@/shared/lib/support/forms/enums/FormActionTypesEnum';
import { FormRedirectionInterface } from '@/shared/lib/support/forms/interfaces/FormRedirectionInterface';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

@Component({
  components: {
    GridCol,
    GridRow,
    MainHeader,
    Loader,
    LoaderOverlay,
    FormButtons,
  },
})
export default class FormLayout extends Vue {
  /**
   * Props
   */
  @Prop() private model!: ORMModel | null;
  @Prop() private validation!: Validation;
  @Prop() private title!: string;
  @Prop() private subtitle?: string;
  @Prop() private settings!: any;
  @Prop() private formsRedirection!: IndexableInterface;
  @Prop() private method!: (pin: string) => Promise<any>;
  @Prop() private module!: any; // TODO
  @Prop() private action!: FormActionTypesEnum;
  @Prop({ default: false }) private initialLoading!: boolean;
  @Prop({ default: false }) private noSaveCreateButton!: boolean;

  /**
   * Data
   */
  private loading: boolean = false;

  /**
   * Getters
   */
  private get dataChanged(): boolean {
    return this.model ? this.model.changed : false;
  }

  private get redirectRoutes(): FormRedirectionInterface {
    return this.formsRedirection[this.module][this.action];
  }

  /**
   * Display getters
   */
  private get displayFieldset(): boolean {
    return !!this.model;
  }

  private get displayInitialLoader(): boolean {
    return this.initialLoading;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  /**
   * Methods
   */
  private async callSaveMethod(saveAction: FormSaveActionsEnum) {
    await Popup.pin(async (pin: string) => {
      this.loading = true;

      try {
        const result: any = await this.method(pin);

        Popup.success();
        this.saveRedirect(saveAction, result);
      } catch (ex) {
        // TODO
      }

      this.loading = false;
    });
  }

  private saveRedirect(saveAction: FormSaveActionsEnum, result: any) {
    const redirectRoute: string | ((result: any) => object) | null | undefined = this.redirectRoutes[saveAction];

    if (redirectRoute !== undefined) {
      this.redirect(redirectRoute, result);
    }
  }

  private redirect(route: string | ((result: any) => object) | null, result: any = {}) {
    if (route === null) {
      return;
    }

    if (typeof route === 'string') {
      if (route === this.$route.name) {
        return this.$router.push({ name: 'redirect', query: { to: route } });
      }

      return this.$router.push({ name: route as string });
    }

    return this.$router.push(route(result));
  }

  /**
   * Handlers
   */
  private onClickSave() {
    this.callSaveMethod(FormSaveActionsEnum.SAVE);
  }

  private onClickSaveClose() {
    this.callSaveMethod(FormSaveActionsEnum.SAVE_CLOSE);
  }

  private onClickSaveCreate() {
    this.callSaveMethod(FormSaveActionsEnum.SAVE_CREATE);
  }

  private onClickCancel() {
    this.redirect(this.redirectRoutes.cancel);
  }
}
