


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vuelidate';

import Sticky from '@/shared/resources/components/Sticky.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import DropdownButton from '@/shared/resources/components/dropdown-button/DropdownButton.vue';
import DropdownItem from '@/shared/resources/components/dropdown-button/DropdownItem.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import FormSaveActionsEnum from '@/shared/lib/support/forms/enums/FormSaveActionsEnum';

@Component({
  components: {
    Icon,
    DropdownItem,
    DropdownButton,
    Button,
    Sticky,
  },
})
export default class FormButtons extends Vue {
  /**
   * Props
   */
  @Prop() private dataChanged!: boolean;
  @Prop() private validation!: Validation;
  @Prop() private settings!: any;
  @Prop() private loading!: boolean;
  @Prop({ default: false, type: Boolean }) private noSaveCreate!: boolean;

  /**
   * Getters
   */
  private saveAction: FormSaveActionsEnum = FormSaveActionsEnum.SAVE;

  /**
   * Disable getters
   */
  private get disableSaveButtons(): boolean {
    return !this.dataChanged || this.loading;
  }

  private get disableCancelButton(): boolean {
    return this.loading;
  }

  /**
   * Display getters
   */
  private get displaySaveCreateButton(): boolean {
    return !this.noSaveCreate;
  }

  /**
   * Getters
   */
  private get saveButtonText(): string {
    return this.$t('buttons.' + this.saveAction).toString();
  }

  private get routeName(): string | null {
    return this.$route.name || null;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.applyCachedSaveAction();
  }

  /**
   * Methods
   */
  private applyCachedSaveAction() {
    if (!this.routeName) {
      return;
    }

    const cached: string = this.settings.getCacheFormSaveButtons(this.routeName);

    if (!cached) {
      return;
    }

    this.saveAction = cached as FormSaveActionsEnum;
  }

  private validateAndEmitSave(saveAction: FormSaveActionsEnum) {
    this.saveAction = saveAction;

    if (this.routeName) {
      this.settings.cacheFormSaveButtons(this.routeName, saveAction);
    }

    if (this.validation) {
      this.validation.$touch();

      if (this.validation.$invalid) {
        return;
      }
    }

    this.$emit(saveAction);
  }

  /**
   * Handlers
   */
  private onClickMainSaveButton() {
    switch (this.saveAction) {
      case FormSaveActionsEnum.SAVE_CLOSE:
        this.onClickSaveClose();
        break;

      case FormSaveActionsEnum.SAVE_CREATE:
        this.onClickSaveCreate();
        break;

      case FormSaveActionsEnum.SAVE:
      default:
        this.onClickSave();
        break;
    }
  }

  private onClickSave() {
    this.validateAndEmitSave(FormSaveActionsEnum.SAVE);
  }

  private onClickSaveClose() {
    this.validateAndEmitSave(FormSaveActionsEnum.SAVE_CLOSE);
  }

  private onClickSaveCreate() {
    this.validateAndEmitSave(FormSaveActionsEnum.SAVE_CREATE);
  }

  private onClickCancel() {
    this.$emit('cancel');
  }
}
