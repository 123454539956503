
































import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/shared/resources/components/Icon.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
    Icon,
  },
})
export default class DropdownButton extends Vue {
  /**
   * Props
   */
  @Prop() private text!: string;
  @Prop({ default: false, type: Boolean }) private caretOnly?: boolean;
  @Prop({ default: 'expand_more' }) private caretIcon?: boolean;

  /**
   * Display getters
   */
  private get displayMainButton(): boolean {
    return !this.caretOnly;
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'custom-dropdown-button': true,
      'custom-dropdown-button__caret-only': this.caretOnly,
    };
  }

  /**
   * Handlers
   */
  private onClick() {
    this.$emit('click');
  }
}
